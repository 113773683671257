<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <SocialSignInButton
        icon="apple"
        provider-name="Apple"
        provider-code="apple"
        @click="loginWithProvider"
      />
      <SocialSignInButton
        icon="google"
        provider-name="Google"
        provider-code="google"
        @click="loginWithProvider"
      />
    </div>
    <WebccFeedbackMessage
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      class="mt-4"
    />
  </div>
</template>

<script lang="ts" setup>
import type { UpdateLoginFlowBody } from '@ory/client'
import { useI18n } from '~/composables/@ihgroup/l10n/useI18n'

const trPrefix = 'guestWorld.components.auth.login.SocialSignIns.'

const { t } = useI18n()

const props = defineProps<{
  flowId?: string
}>()

const requestPending: Ref<boolean> = ref(false)
const errorMessage: Ref<string | undefined> = ref(undefined)

// Calls the submit login ory api call with the given social login provider
async function loginWithProvider(provider: string) {
  useTracking().handlers?.guestworld.loginSocial(capitalize(provider))
  requestPending.value = true

  if (!props.flowId) {
    console.error('No flowId provided')
    requestPending.value = false
    return
  }

  const loginData: UpdateLoginFlowBody = {
    method: 'oidc',
    provider,
    traits: {
      lang: useL10N().language,
    },
  }

  // Call the register with provider api
  try {
    // Register the user
    await useAuth().loginUser(props.flowId, loginData)
  } catch (error: any) {
    // Check if we get the browser redirect. If so, we can ignore the error and can forward to the sign in of the social login provider
    const errorData = error.response?.data

    if (errorData && errorData.redirect_browser_to) {
      await navigateTo(errorData.redirect_browser_to, { external: true })
      requestPending.value = false
      return
    }

    // If the redirect is not there, it is an actual error and we need to show the error
    console.error('Error registering with provider:', error)
    errorMessage.value = t(`${trPrefix}initError` as TranslationKey)
  }
}
</script>

<style></style>
