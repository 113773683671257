<template>
  <div class="flex flex-col max-w-md grow">
    <StepBackButton
      v-if="returnTo && returnTo !== '/'"
      class="absolute top-4 sm:top-8 left-0"
      is-link
      external
      :link="stepbackLink"
    />

    <span class="text-2xl sm:text-3xl font-bold text-center sm:px-20 mb-8">
      {{ $t(`${trPrefix}signInTitle`) }}
    </span>

    <WebccForm ref="formRef" v-model="formdata" @submit="submit">
      <WebccInput
        v-model="email"
        class="mb-4"
        name="email"
        :label="$t(`${trPrefix}email`)"
        type="email"
        :valid="fieldsValid"
      />

      <WebccInput
        v-model="password"
        class="mb-2"
        name="password"
        :label="$t(`${trPrefix}password`)"
        type="password"
        :valid="fieldsValid"
      />
      <div class="w-full text-right">
        <NuxtLink
          :to="forgotPasswordLink"
          class="text-sm text-txt-weak hover:underline"
        >
          {{ $t(`${trPrefix}forgotPassword`) }}
        </NuxtLink>
      </div>
      <WebccFeedbackMessage
        v-if="errorMessage"
        :message="errorMessage"
        type="error"
        class="my-4"
      />
      <WebccButton
        class="my-4 w-full"
        variant="theme"
        size="lg"
        :loading="requestPending"
        >{{ $t(`${trPrefix}signIn`) }}
      </WebccButton>
    </WebccForm>
    <UserAgreement class="mb-8" />
    <WebccOrDivider class="mb-6" />

    <!-- Social sign ups -->
    <SocialSignIns :flow-id="loginFlow?.id" class="mb-6" />

    <span class="text-2xl mt-auto mb-8 text-center">
      {{ $t(`${trPrefix}newToIH`) }}
      <NuxtLink class="text-thm hover:underline font-bold" :to="registerLink">
        {{ $t(`${trPrefix}register`) }}
      </NuxtLink>
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { LoginFlow, UpdateLoginFlowBody } from '@ory/client'

const trPrefix = 'guestWorld.components.auth.login.LoginForm.'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    returnTo?: string
  }>(),
  {
    returnTo: '/',
  },
)

const email = ref('')
const password = ref('')
const loginFlow: Ref<LoginFlow | undefined> = ref(undefined)
const requestPending: Ref<boolean> = ref(false)
const fieldsValid: Ref<boolean | undefined> = ref(undefined)
const errorMessage: Ref<string | undefined> = ref(undefined)
const formRef: Ref<HTMLFormElement | null> = ref(null)

const formdata = ref({
  email: '',
})

await initLoginFlow()

const forgotPasswordLink = computed(() => {
  return `/forgotpassword${getQueryStringFromObject(useRoute().query)}`
})

const registerLink = computed(() => {
  return `/register${getQueryStringFromObject(useRoute().query)}`
})

const stepbackLink = computed(() => {
  // If the returnTo starts with / add the host as well as base path
  let base
  if (props.returnTo.startsWith('/')) {
    base = useRequestURL().origin
  }
  const returnURL = new URL(decodeURIComponent(props.returnTo), base)
  returnURL.searchParams.delete('signedIn')
  return returnURL.toString()
})

// Initialize the login flow
async function initLoginFlow() {
  // Only fetch on client, because it does not need to be on the server also
  if (import.meta.client) {
    try {
      loginFlow.value = await useAuth().getLoginFlowData(props.returnTo)
    } catch (error) {
      console.error('Could not fetch login flow data. ', error)
      errorMessage.value = t(`${trPrefix}unknownError`) as TranslationKey
      // throw error
    }
  }
}

async function submit() {
  const loginData: UpdateLoginFlowBody = {
    identifier: email.value,
    password: password.value,
    method: 'password',
  }

  // Nothing to login if there is no login flow
  if (!loginFlow.value) return

  try {
    requestPending.value = true
    await useAuth().loginUser(loginFlow.value.id, loginData)

    requestPending.value = false

    // delete the returnTo query parameter, because it is not needed anymore
    const query = useParams().removeQueryParams(useRoute().query, ['returnTo'])

    useTracking().handlers?.guestworld.login()

    // If return to is not set, go to the start page with external navigation
    if (props.returnTo === '/') {
      const homePath = getHomeNavigation(props.returnTo, query)
      await navigateTo(homePath, { external: true })
    } else {
      await navigateTo(
        {
          path: decodeURIComponent(props.returnTo),
          query: { ...query, language: useParams().language },
        },
        { external: true },
      )
    }
  } catch (error: any) {
    console.log('Login failed with error. ', error.response)
    errorMessage.value = t(`${trPrefix}loginError` as TranslationKey)
    requestPending.value = false
    fieldsValid.value = false
  }
}

// Watches, if one of the form fields changes value
watch([email, password], () => {
  fieldsValid.value = undefined
  errorMessage.value = undefined
})
</script>

<style></style>
