<template>
  <div class="container-fluid-lg flex justify-center grow relative">
    <LoginForm class="mt-28" :return-to="returnTo" />
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const route = useRoute()
const urls = useURLs()
const showToasterEvt = useEventBus<ToasterDto>('showToaster')

const returnTo = ref('/')

definePageMeta({
  layout: 'login',
  pageTransition: {
    name: 'login-page',
    mode: 'out-in',
  },
})

useHead({ title: `${t('guestWorld.components.auth.login.LoginForm.signIn')}` })

onBeforeMount(() => {
  const { query } = useRoute()
  if (!query) {
    return
  }
  const returnToQuery = query.returnTo as string
  // delete the returnTo query from the url
  if (returnToQuery) {
    // delete query.returnTo

    // build the query string for the returnTo and add it to the returnTo value
    // add the query params and encode the query to support more that one query param
    returnTo.value =
      returnToQuery + getQueryStringFromObject(useRoute().query, true)
  }
})

onMounted(async () => {
  const isDeletedParam = route.query?.isDeleted || ''

  if (isDeletedParam) {
    showToasterEvt.emit({
      type: 'success',
      message: t(
        'guestWorld.components.myaccount.profile.AccountDeleteModal.successMessage',
      ),
    })

    // TODO find a way to have both readable toaster and query param removal
    await urls.cleanQuery(['isDeleted'], 3000)
  }
})
</script>

<style></style>
